var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "home",
    },
    [
      _c(
        "div",
        {
          class: [
            "container container--full-screen",
            { mobileScreen: _vm.isMobile },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("iq-title", { attrs: { "class-name": "mobileHeader" } }, [
                _vm._v("Квоты:"),
              ]),
            ],
            1
          ),
          _vm.isShowQuotas
            ? [
                _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
                  _c(
                    "div",
                    _vm._l(_vm.getKeysQuotaSortable, function (item) {
                      return _c("QuotaDay", {
                        key: item,
                        attrs: { "day-data": _vm.getQuota(item) },
                      })
                    }),
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          !_vm.isShowQuotas && !_vm.isLoading
            ? [
                _c(
                  "transition",
                  { attrs: { name: "el-fade-in-linear" } },
                  [_c("QuotaEmpty")],
                  1
                ),
              ]
            : _vm._e(),
          _c("dialog-improve-quota"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }