<template>
  <div v-loading="isLoading" class="home">
    <div
      :class="['container container--full-screen', { mobileScreen: isMobile }]"
    >
      <div class="header">
        <iq-title class-name="mobileHeader">Квоты:</iq-title>
      </div>

      <template v-if="isShowQuotas">
        <transition name="el-fade-in-linear">
          <div>
            <QuotaDay
              v-for="item in getKeysQuotaSortable"
              :key="item"
              :day-data="getQuota(item)"
            />
          </div>
        </transition>
      </template>

      <template v-if="!isShowQuotas && !isLoading">
        <transition name="el-fade-in-linear">
          <QuotaEmpty />
        </transition>
      </template>

      <dialog-improve-quota />
    </div>
  </div>
</template>

<script>
import {
  FETCH_SUPPLIERS_ALL,
  GET_USER_EXPORTERS,
} from '@/views/exporter/store/actions'

import {
  FETCH_LIMIT_QUOTAS,
  GET_IS_LOADING_QUOTAS,
  GET_QUOTAS,
  SET_QUOTAS_LIST,
} from '@/views/home/store/actions'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DialogImproveQuota from '../dialogs/dialogImproveQuota/DialogImproveQuota.vue'
import IqTitle from '@/views/ui/components/typography/IqTitle'
import QuotaDay from '@/views/home/components/quota-day/QuotaDay'
import QuotaEmpty from '@/views/home/components/quota-empty/QuotaEmpty'
export default {
  name: 'ExporterQuotas',
  components: { QuotaEmpty, IqTitle, DialogImproveQuota, QuotaDay },
  computed: {
    ...mapGetters({
      userExporters: GET_USER_EXPORTERS,
      getTerminalCurrentId: GET_TERMINAL_CURRENT_ID,
      quotasList: GET_QUOTAS,
      isLoading: GET_IS_LOADING_QUOTAS,
      isMobile: GET_IS_MOBILE,
    }),
    isShowQuotas() {
      return Object.keys(this.quotasList).length > 0
    },
    getKeysQuotaSortable() {
      return Object.keys(this.quotasList).sort()
    },
    userExporterIds() {
      return this.userExporters.map(item => item.id)
    },
  },
  watch: {
    userExporters() {
      this.getQuotas(this.userExporterIds)
      this.fetchSuppliers({
        exporter_id: this.userExporterIds,
      })
    },
  },
  mounted() {
    if (this.userExporters.length) {
      this.getQuotas(this.userExporterIds)
      this.fetchSuppliers({
        exporter_id: this.userExporterIds,
      })
    }
  },
  beforeDestroy() {
    this.clearQuotas({ quotas: [] })
  },
  methods: {
    ...mapActions({
      getQuotas: FETCH_LIMIT_QUOTAS,
      fetchSuppliers: FETCH_SUPPLIERS_ALL,
    }),
    ...mapMutations({
      clearQuotas: SET_QUOTAS_LIST,
    }),
    getQuota(key) {
      return this.quotasList[key]
    },
  },
}
</script>

<style lang="sass" scoped>
.container--full-screen
  padding-bottom: 50px
</style>
